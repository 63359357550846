.class-status {
  &-title {
    text-align: center;
    color: white;
    @media (max-width: $mobile-small) {
      font-size: 16px !important;
    }
  }

  &-title-container {
    padding-left: 40;
    padding-right: 40;
    margin-bottom: 18px !important;
  }
  &-title-container-mobile {
    @media (max-width: $mobile-small) {
      margin-bottom: 0px !important;
    }
    margin-bottom: 18px !important;
  }

  &-no-class {
    text-align: center;
    color: white;
  }

  &-new-lecture {
    color: #0072ef !important;
    text-decoration: underline !important;
  }

  &-card {
    color: white;
    background-color: #305e7d;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    border: 1px solid #eed879;

    &-title {
      text-transform: uppercase;
      font-weight: 700;
    }

    &-divider {
      margin-left: 7px;
      margin-right: 7px;
      border: none;
      border-top: 1px solid #eed879;
      text-align: center;
    }

    &-divider-small {
      margin: 0 auto;
      margin-top: 5px;
      border: none;
      border-top: 0.5px solid #eed879;
      text-align: center;
      width: 40px;
      justify-content: center;
    }

    &-divider-slide-display {
      margin-left: 7px;
      margin-right: 7px;
      border: none;
      border-top: 2px solid #eed879;
      text-align: center;
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .start-lecture,
  .action-button {
    max-width: 250px;
    justify-self: center;
    .v-btn__content {
      font-weight: bold;
    }
  }
  .text-field {
    @media (max-width: $mobile-small) {
      height: 20px;
    }
    height: 27px;
  }
  .button-custom {
    width: 100%;
    color: black;
    height: 27px;
    font-size: 12px;
    letter-spacing: 0.0892857143em;
  }

  .button-custom-action {
    width: 100%;
    color: black;
    height: 40px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.0892857143em;
    max-width: 250px;
  }
  .permission-yes-no {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .class-status-text {
    font-size: 12px;
  }

  .class-status-lecture-container {
    @media (max-width: $mobile-small) {
      padding-top: 14px !important;
    }
    padding-top: 14px !important;
  }

  .permission-container {
    @media (max-width: $mobile-small) {
      margin-top: -8px;
    }
    @media (max-width: $mobile) {
      margin-top: -5px;
    }

    margin-top: -14px;
  }

  .container-section {
    @media (max-width: $mobile) {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
  }

  .container-section-action {
    @media (max-width: $mobile) {
      padding-left: 8px !important;
    }
  }
}
