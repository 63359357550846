@import "../styles/variables";

.ac-card {
  width: 160px;
  height: 150px;
  border-radius: 10px !important;
  border: 1px solid #ffffff;

  &-active {
    width: 160px;
    height: 150px;
    border-radius: 10px !important;
    border: 2px solid $yellow-react !important;
    cursor: pointer;
  }

  &-future {
    width: 160px;
    height: 150px;
    border-radius: 10px !important;
    border: 2px solid $light-blue-header !important;
    cursor: pointer;
  }

  &-header-live {
    background-color: white;
  }

  &-header-num {
    padding-top: 2px;
    padding-left: 5px;
    color: white;
    &-live {
      padding-top: 2px;
      padding-left: 5px;
      color: $blue-dianne;
    }
    &-future {
      padding-top: 2px;
      padding-left: 5px;
      color: $blue-dianne;
    }
  }

  &-header-next {
    background: $red-light;
    color: white;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 4px;
    margin-top: 3px !important;
    // padding: 1px;
  }

  &-header-icon {
    width: 12px !important;
    height: 12px !important;
    color: white;
    &-live {
      width: 12px !important;
      height: 12px !important;
      color: $blue-dianne;
    }
    &-future {
      width: 12px !important;
      height: 12px !important;
      color: $blue-dianne;
    }
  }

  &-header-title-container {
    margin-top: -8px;
  }

  &-header-title {
    font-weight: 700 !important;
    font-size: 14px !important;
    color: white !important;
    &-live {
      font-weight: 700 !important;
      font-size: 14px !important;
      color: $blue-dianne !important;
    }
    &-future {
      font-weight: 700 !important;
      font-size: 14px !important;
      color: $blue-dianne !important;
    }
  }

  &-header-subtitle {
    font-size: 11px !important;
    color: white;
    &-live {
      font-size: 11px !important;
      color: $blue-dianne;
    }
    &-future {
      font-size: 11px !important;
      color: $blue-dianne;
    }
  }

  &__divider {
    background-color: white;
    &-future {
      background-color: $blue-dianne;
    }
  }
}
