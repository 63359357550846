.lecture-document {
  position: relative;
  .react-pdf__Document {
    justify-content: center !important;
    align-items: center !important;
    background-color: white !important;
    text-align: center !important ;
    display: flex;
    width: 100% !important;
    height: 100% !important;
  }
  .react-pdf__Page {
    text-align: center !important ;
  }

  .react-pdf__Page__canvas {
    text-align: center !important ;
    width: 100% !important;
    height: 100% !important;
    max-height: 85vh !important;
    aspect-ratio: auto 16 / 9;
  }

  .react-pdf__Page__annotations {
    display: none;
  }

  .react-pdf__Page__textContent {
    display: none !important;
  }
}

.lecture-document-full {
  position: relative;
  .react-pdf__Document {
    justify-content: center !important;
    align-items: center !important;
    background-color: white !important;
    text-align: center !important ;
    display: flex;
    width: 100% !important;
    height: 100% !important;
  }
  .react-pdf__Page {
    height: 100% !important;
    text-align: center !important ;
  }

  .react-pdf__Page__canvas {
    text-align: center !important ;
    width: 100% !important;
    height: 100% !important;
    max-height: 90vh !important;
    aspect-ratio: auto 16 / 9;
  }

  .react-pdf__Page__annotations {
    display: none;
  }

  .react-pdf__Page__textContent {
    display: none !important;
  }
}

.preview-document {
  position: relative;
  .react-pdf__Document {
    justify-content: center !important;
    align-items: center !important;
    background-color: white !important;
    text-align: center !important ;
    display: flex;
    width: 100% !important;
    height: 100% !important;
  }
  .react-pdf__Page {
    text-align: center !important ;
  }

  .react-pdf__Page__canvas {
    text-align: center !important ;
    width: 100% !important;
    height: 100% !important;
    max-height: 90vh !important;
    aspect-ratio: auto 16 / 9;
  }

  .react-pdf__Page__annotations {
    display: none;
  }

  .react-pdf__Page__textContent {
    display: none !important;
  }
}

.slides-bottom-container {
  position: absolute;
  overflow: auto;
  white-space: nowrap;
  bottom: 0;
  width: 100%;
  height: 15vh;
  text-align: center;

  .react-pdf__Document {
    justify-content: flex-start !important;
    align-items: center !important;
    background-color: white !important;
    display: flex;
    width: 100% !important;
    height: 100% !important;
  }

  .react-pdf__Page__canvas {
    text-align: left !important ;
    width: 100% !important;
    height: 100% !important;
    max-height: 85vh !important;
    aspect-ratio: auto 16 / 9;
  }

  .react-pdf__Page__annotations {
    display: none;
  }

  .react-pdf__Page__textContent {
    display: none !important;
  }
}

.slides-bottom-show {
  position: absolute;
  width: 100%;
  display: none;
  bottom: 0;
  text-align: center;

  .react-pdf__Document {
    justify-content: center;
    display: flex;
  }

  .react-pdf__Page {
    width: 100% !important;
    text-align: center !important ;
    max-height: 6vh !important;
    overflow: hidden;
  }

  .react-pdf__Page__canvas {
    text-align: center !important ;
    width: 80px !important;
    height: 100% !important;
  }

  .react-pdf__Page__annotations {
    display: none;
  }

  .react-pdf__Page__textContent {
    display: none !important;
  }

  .slide-bottom {
    cursor: pointer;
  }
}

.slides-bottom-container:hover > .slides-bottom-show {
  display: block;
  text-align: center;
}

.arrow-up {
  text-align: center;
}

.arrow-icon {
  font-size: 50px;
  color: $yellow-react;
  &-disabled {
    color: rgba(78, 76, 76, 0.788);
  }
}
