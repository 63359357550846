.my-lectures {
  text-align: center;

  &__empty {
    color: white;
    font-size: 20px;
    padding: 40px;
  }

  .ac-card__actions {
    max-width: 150px;

    .v-btn {
      font-weight: bold;
    }
  }

  .ac-card__title {
    max-height: 50px;
  }
}

.lecture-card {
  &--active {
    border: 2px solid var(--v-green-base) !important;
  }

  &__button {
    width: 100%;
    display: block;
  }

  &__button-edit {
    height: 20px;
    position: relative;
    top: -10px;
    float: right;
    align-content: center;
  }

  &__date {
    height: 10%;
    font-weight: 700;
    font-size: 14px;
    color: white;
    position: absolute;
    text-align: center;
    width: 100%;
    top: 12%;
  }

  &__title {
    height: 70%;
    color: white;
    text-align: center;

    & ~ .v-divider {
      border-color: #3b3b3b !important;
    }
  }

  &__subtext {
    font-weight: 500;
    font-size: large;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: white;
    margin-bottom: 10px;
  }

  &__fake-card {
    width: 160px !important;
    margin-left: 10px !important;
  }
}
