@import "../styles/variables";
.remote {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  height: calc(93vh - #{$header-height} - #{$subheader-height});

  &__container {
    @media (min-width: $tablet) {
      padding-top: 20px;
    }
    max-height: 450px;
    height: calc(100vh - #{$header-height} - #{$subheader-height});
    min-height: 320px;

    @media (max-width: $tablet) {
      padding: 25px 0 0 0;
      height: calc(93vh - #{$header-height} - #{$subheader-height});
    }

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    &.is-disabled {
      opacity: 0.5;
    }
  }

  &__column {
    display: grid;
    // margin-left: 1px;
    grid-template-rows: 1fr 1fr 1fr;
    // padding: 10px;

    height: 100%;

    @media (max-width: $tablet) and (orientation: portrait) {
      height: 92%;
    }

    &:nth-of-type(2) {
      border: solid 2px #39a5c0;
      border-radius: 100px;
      // fix border making circles not same dimensions
      margin-left: -2px;
      margin-right: -2px;
    }
  }

  &__title {
    color: #62daff;
    text-align: center;
    font-size: 14px;
    margin-top: 20px;

    @media (max-width: $mobile) {
      height: 14px;
      width: 100%;
      margin-top: 40px;
      margin-bottom: 0;
    }

    @media (max-width: $tablet) and (orientation: portrait) {
      position: relative;
      top: -45px;
    }

    @media (max-width: $tablet) and (orientation: landscape) {
      position: relative;
      top: 35px;
    }
  }

  .great-button {
    @media (max-width: $tablet) {
      position: relative;
      top: 15px;
    }
  }

  &__reset-container {
    width: 100%;
    @media (max-width: $tablet) {
      margin-top: 2px;
    }
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
  &__reset-button {
    background-color: #fbd806 !important;
    color: $blue-dianne !important;
    border: 1px solid $blue-dianne;
  }
}
