.question-answers {
  &-q-title {
    color: white !important;
    font-size: 12px;
  }

  &-q-answer {
    color: white !important;
    font-size: 14px;
    overflow-wrap: break-word;
  }

  &-q-reactions {
    color: white !important;
    font-size: 14px;
  }
}
