.ac-about-page {
  position: relative;
  color: white;
  @media (max-width: $mobile) {
    margin-top: 90px;
  }

  &__white-line {
    width: 70%;
    border-top: 2px solid white;
  }
}
